<template>
  <section class="page-bg">
    <div class="banner mobile_page-container" id="Weare"></div>
    <h2>Introduction</h2>
    <p class="company-intro mobile_page-container">ドルフィンAIは自社開発のAI技術を持つ研究開発型のハイテク企業です。独自技術の音声対話意図理解モデル(Dolphin Large Language Models)に基づき、音声認識、音声生成、意味理解、テキスト生成、機械翻訳、声紋認識、その他音声対話のフルチェーン技術を開発し、日本語、英語、中国語、韓国語、フランス語、スペイン語、ロシア語、ドイツ語、チベット語、タイ語、ベトナム語など20以上の言語に対応しています。 また、SaaSやオンプレミスのサービス形態を通じて、教育、医療、金融、その他の分野のお客様に基礎となるAI技術と業界ソリューションを提供しています。</p>
    <div class="address mobile_page-container" id="Profile">
      <h2>Profile</h2>
      <ul>
        <li>
          <span class="title">会社名</span>
          <span class="info">株式会社Dolphin AI</span>
        </li>
        <li>
          <span class="title">設立</span>
          <span class="info">2023年12月</span>
        </li>
<!--        <li>-->
<!--          <span class="title">資本金</span>-->
<!--          <span class="info">3,000,000円</span>-->
<!--        </li>-->
        <li>
          <span class="title">代表取締役社長</span>
          <span class="info">朝倉(アサクラ) 匡廣(マサヒロ)</span>
        </li>
        <li>
          <span class="title">事業内容</span>
          <ol>
            <li>DolphinSOE 英語発音評価サービスの開発＆販売</li>
            <li>DolphinVoice 音声対話SaaS Platformの開発＆販売</li>
          </ol>
        </li>
        <li>
          <span class="title">アクセス情報</span>
          <div class="map">
            <p>〒170-0013</p>
            <p>東京都豊島区東池袋1-18-1 Hareza Tower 20F</p>
            <ol>
              <li>JR山手線・埼京線 池袋駅東口（30番出口）より徒歩4分</li>
              <li>東京メトロ丸の内線・副都心線・有楽町線 池袋駅東口（30番出口）より徒歩4分</li>
              <li>西武池袋線 池袋駅東口（30番出口）より徒歩4分</li>
              <li>東武東上線 池袋駅東口（30番出口）より徒歩4分</li>
            </ol>
            <div>
              <iframe
                  src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1619.4010139286677!2d139.71289824932452!3d35.731087078279494!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e3!4m5!1s0x60188d5d4043e0dd%3A0x213775d25d2b034d!2z5p2x5Lqs6YO96LGK5bO25Yy65Y2X5rGg6KKL77yR5LiB55uu77yS77yYIOaxoOiii-mnhQ!3m2!1d35.7295028!2d139.7109001!4m5!1s0x60188d99ee19b98b%3A0x287b2cf80a55fb87!2z5p2x5Lqs6YO96LGK5bO25Yy65p2x5rGg6KKL77yR5LiB55uu77yR77yY4oiS77yRIEhhcmV6YSBUb3dlcg!3m2!1d35.7324188!2d139.7152077!5e0!3m2!1sja!2sjp!4v1703747968784!5m2!1sja!2sjp"
                  width="816"
                  height="216"
                  style="border:0;"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </li>
        <li>
          <span class="title">電話番号</span>
          <span class="info">(+81) 03-6775-4523</span>
        </li>
        <li>
          <span class="title">メールアドレス</span>
          <span class="info">contact@dolphin-ai.jp</span>
        </li>
      </ul>
    </div>
    <div class="founder" id="Founder">
      <h2>Founder</h2>
      <div class="member">
        <div class="member-container1">
          <div class="member-intro">
            <div class="infos">
              <p class="name">朝倉(アサクラ)<br/> 匡廣(マサヒロ)</p>
              <p class="position">CEO (Chief Executive Officer 代表取締役社長)</p>
            </div>
            <p class="photo">
              <img src="~@/assets/images/company_mobile/coo.png">
            </p>
          </div>
          <div class="intro-details" ref="coo">富士通、Bell System24、Advanced Mediaなど日本大手IT＆AI事業会社で、プロダクト＆マーケティング＆海外事業開発などエンジニアや管理職歴任。物流、コールセンター、AI＆IOT領域で新規事業0-1、1-10経験豊富。AI＆IOT領域で10年ぐらい日本国内＆海外において外部アライアンス、プロダクト企画、マーケティング、企業案件導入など実績多数。</div>
        </div>
        <div class="member-container">
          <div class="member-intro">
            <div class="infos">
              <p class="name">党(トウ)<br/> 建武(タケシ)</p>
              <p class="position">CSO (Chief Scientific Officer 主任研究員)</p>
            </div>
            <p class="photo">
              <img src="~@/assets/images/company_mobile/ceo.png">
            </p>
          </div>
          <ul class="intro-details" ref="ceo">
            <li>1982年 中国清華大卒業，84年同大修士</li>
            <li>1984-1988年 天津大学講師</li>
            <li>1992年 静岡大大学院博士課程博士（工学）</li>
            <li>1992-2001年 ATR 人間情報通信研究所主任研究員</li>
            <li>1998-1999年 カナダWaterloo大学工学科客員研究員を兼任</li>
            <li>2001-2022年 北陸先端科学技術大学院大学教授</li>
            <li>2002-2003年 仏国立科学研究センター音声通信研究所＆グルノーブル工科大＆ステンダル大など研究員を兼任</li>
            <li>2022年-現在 北陸先端科学技術大学院大学名誉教授 </li>
            <li>音声生成・合成，音声認識，言語モデル，言語障害，ジェネレーティブAIなどに従事。</li>
            <li>電信通信学会，日本音響学会，ASA，ACMなどの会員で、JASA、IEEE Trans、Speech Communication，Neuroscienceなどの国際有名学術雑誌で100本以上論文発表。</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'company',
  mounted () {
    this.$nextTick(() => {
      this.advScroll (this.$route.query.id)
    })
  },
  watch: {
    $route() {
      this.advScroll (this.$route.query.id)
    }
  },
  methods: {
    advScroll (targetID) {
      if (targetID !== 'Weare') {
        const targetElement = document.querySelector(`#${targetID}`)
        targetElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
h2 {
  font-size: 80px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 90px;
  color: rgba(47, 41, 223, 1);
  text-align: left;
  vertical-align: top;
  margin-bottom: 30px;
}
.banner {
  background: url('../assets/images/company_mobile/company_banner.jpg') no-repeat left top;
  background-size: 100% 100%;
  height: 165px!important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.company-intro {
  min-height: 200px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 28px;
  color: rgba(60, 60, 60, 1);
  text-align: left;
  vertical-align: top;
  background: url('../assets/images/company_mobile/company_bg.png') no-repeat 45px -50px;
  background-size: 390px 305px;
}
.page-bg {
  >h2 {
    padding: 0 24px;
    margin-top: 30px;
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
  }
}
.address {
  padding-bottom: 40px;
  h2 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 16px;
  }
  ul {
    >li {
      min-height: 30px;
      line-height: 30px;
      border-bottom: 1px solid rgba(255, 255, 255, 1);
      display: flex;
      .title {
        width: 100px;
        flex-shrink: 0;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 0px;
        line-height: 20px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: middle;
        padding-top:10px;
      }
      .info {
        flex: 1;
      }
      .info, .map>p, ol>li {
        font-size: 12px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 20px;
        color: rgba(0, 0, 0, 1);
        text-align: left;
        vertical-align: middle;
        padding-top:10px;
        padding-bottom:10px;
      }
    }
    >li:nth-of-type(9) {
      border-bottom: none;
    }
  }
  .map, .map>div {
    width: 100%;
  }
  .map>div {
    height: 216px;
    margin-bottom: 20px;
  }
  .map>div>iframe {
    width: 100%!important;
    height: 100%!important;
  }
}
ol {
  padding-left: 15px;
  >li {
    list-style: disc;
  }
}
.founder {
  height: 980px;
  background: url('../assets/images/company_mobile/founder.jpg') no-repeat left top;
  background-size: 100% 100%;
  h2 {
    font-size: 32px;
    font-weight: 700;
    letter-spacing: 0px;
    line-height: 36px;
    color: rgba(255, 255, 255, 1);
    margin-bottom: 35px;
    padding-left: 24px;
    padding-top:24px;
  }
  .member {
    width: 342px;
    position: relative;
    display: block;
    justify-content: center;
    margin:auto;
  }
  .member-container {
    width: 342px;
    height: 491px;
    border-radius: 28px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(234, 230, 242, 1) 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom:48px;
  }
  .member-container1 {
    width: 342px;
    height: 304px;
    border-radius: 28px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(234, 230, 242, 1) 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom:48px;
  }

  .infos {
    position: absolute;
    z-index: 4;
    top: 33px;
    left: 20px;
    .name {
      font-size: 18px;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 21.1px;
      color: rgba(60, 60, 60, 1);
      text-align: left;
      vertical-align: top;
      padding-bottom:7px;
      //padding-left:20px;
    }
    .position {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 16.41px;
      color: rgba(60, 60, 60, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  .photo {
    position: absolute;
    z-index: 1;
    top: -30px;
    left: 210px;
    width: 105.57px;
    height: 105.57px;
    background: url('../assets/images/company/member_bg.png') no-repeat left top;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 105.57px;
      height: 105.57px;
    }
  }

  .intro-details {
    z-index: 1;
    opacity: 1;
    >li {
      list-style: disc;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 20px;
      color: rgba(0, 0, 0, 1);
      color: rgba(0, 0, 0, 1);
      text-align: left;
      vertical-align: top;
    }
  }
  ul.intro-details {
    padding-left: 15px;
    width: 275px;
    margin:auto;
    margin-top:-620px;
  }
  div.intro-details {
    width: 302px;
    margin-top:-1358px;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
    text-align: left;
    vertical-align: top;
  }
  .intro-details {
    transform: translateY(728px);
  }
  .intro-details.mouse-enter {
    transform: translateY(0);
    transition: transform 0.5s linear;
  }
  .intro-details.mouse-leave {
    transform: translateY(728px);
    transition: transform 0.5s linear;
  }
}
</style>
